import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  where,
  or
} from "firebase/firestore";
import { db } from "../firebase";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [utente] = useAuthState(auth);
  const navigate = useNavigate();
  const [attivita, setAttivita] = useState([]);

  const apriAttivita = (id) => () => {
    setAnchorEl(null);
    navigate("/gestisci/" + id);
  };

  const nuovaAttivita = () => {
    setAnchorEl(null);
    navigate("/crea");
  };

  const signOut = () => {
    auth.signOut();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
      if(utente){
        const q = query(
          collection(db, "attivita"),
          or(where("proprietari", "array-contains", utente.uid),where("dipendenti", "array-contains", utente.uid)),
          orderBy("nomeAttivita"),
          limit(10)
        );

        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
          let attivita = [];
              QuerySnapshot.forEach((doc) => {
                attivita.push({nome: doc.data().nomeAttivita, immagine: doc.data().immagineAttivita, id: doc.id });
              });
              //console.log(attivita);
              setAttivita(attivita);
        });
        return () => unsubscribe;
      }else{
        return;
      }
  }, []);

  return (
    <AppBar position="fixed" color="luogoraBlue">
      <Toolbar>
        <CardHeader avatar={ <Avatar alt={utente.displayName} src={utente.photoURL}/>} title={<Typography variant="body2">{utente.displayName}</Typography>}/>
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenu} sx={{ mr: 1 }}>
          <MenuIcon />
        </IconButton>
        
        <Menu sx={{maxWidth:"80%"}} id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
          {attivita?.map((a) => (     
            <MenuItem key={a.id} onClick={apriAttivita(a.id)}>
              <ListItemIcon>
                <Avatar alt="Shop" variant="rounded" src={a.immagine} sx={{ width: 24, height: 24 }}/>
              </ListItemIcon>
              <ListItemText primary={<Box sx={{maxWidth:"100%",overflow:"hidden",textOverflow:"ellipsis"}}>{a.nome}</Box>} secondary="Gestisci attività"/>
            </MenuItem>
          ))}
          <MenuItem onClick={nuovaAttivita}>
            <ListItemIcon>
              <AddBusinessIcon />
            </ListItemIcon>
            <ListItemText>Aggiungi attività</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Impostazioni</ListItemText>
          </MenuItem>
          <MenuItem onClick={signOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Esci</ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
