import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
  limit
} from "firebase/firestore";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

const Dipendente = ({utente,rimuovi}) => {
  dayjs.locale('it');

  const rimuoviDipendente = () => {
    rimuovi(utente)
  };

  return (                              
    <Box sx={{display: 'flex', flexDirection:"row",alignItems:"center", justifyContent:"left", width:"100%",gap:1,mb:1}}>
      <Avatar alt='u' src={utente.immagine}/>
      <Typography variant="body2" sx={{flexGrow:1}}>{utente.nome}</Typography>
      <IconButton aria-label="delete" onClick={rimuoviDipendente}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </Box>
  )
};

export default Dipendente;
