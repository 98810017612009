import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Mappa from "./Mappa";
import { useNavigate } from "react-router-dom";

const Attivita = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [attivita, setAttivita] = useState();

  useEffect(() => {
    if(!attivita)
    {
      (async () => {
        const docRef = doc(db, "attivita", id);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          setAttivita(docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }
  });
  
  const prenotaClick = (event) => {
    navigate("/prenota/" + id);
  };

  return (
    <Box>
      {attivita &&
        <Box style={{ position: "fixed", top: 0,left: 0,right:0,bottom:0}} sx={{ display: 'flex', flexDirection:"column",alignItems:"center"}}>
          <CardHeader
            avatar={
                <Avatar aria-label="shop" variant="rounded" src={attivita.immagineAttivita}>
                ?
                </Avatar>
            }
            title={<Typography sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>{attivita.nomeAttivita}</Typography>}
            subheader={<Typography variant="caption" color="grey" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>{attivita.tipoAttivita}</Typography>}
          />
          <Box sx={{px:3, display: 'flex', flexDirection:"column",alignItems:"center",gap:1, flexGrow: 1}}>
            <Typography variant="body2">{attivita.descrizione}</Typography>

            <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%"}}>
              <AccessTimeIcon/>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">LUN</Typography>
                <Typography variant="caption">8:00-12:00 14:00-18:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">MAR</Typography>
                <Typography variant="caption">8:00-12:00 14:00-18:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">MER</Typography>
                <Typography variant="caption">8:00-12:00 14:00-18:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">GIO</Typography>
                <Typography variant="caption">8:00-12:00 14:00-18:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">VEN</Typography>
                <Typography variant="caption">10:00-12:00 16:00-18:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">SAB</Typography>
                <Typography variant="caption">10:00-12:00</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection:"row",justifyContent:"space-between", width:"100%"}}>
                <Typography variant="body1">DOM</Typography>
                <Typography variant="caption">chiuso</Typography>
              </Box>
            </Box>

            <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%", flexGrow: 1}}>
              <PlaceIcon/>
              <Typography variant="caption">{attivita.indirizzo}</Typography>
              <Box sx={{width:"100%", flexGrow: 1}}>
                  <Mappa posizione={{ lat: -3.745, lng: -38.523 }}/>
              </Box>
            </Box>

            <Box sx={{display: 'flex', flexDirection:"row",alignItems:"center",justifyContent:"space-evenly", width:"100%"}}>
              <IconButton aria-label="Facebook">
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label="Instagram">
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="Email">
                <EmailIcon />
              </IconButton>
              <IconButton aria-label="WhatsApp">
                <WhatsAppIcon />
              </IconButton>
              <IconButton aria-label="Phone">
                <LocalPhoneIcon />
              </IconButton>
            </Box>

            <Button onClick={prenotaClick} variant="contained" sx={{m:2}}>Prenota</Button>
            
            </Box>
        </Box>
      }
    </Box>
  )
};

export default Attivita;
