import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import ImpostazioniAttivita from "./ImpostazioniAttivita";
import ImpostazioniOrario from "./ImpostazioniOrario";
import ImpostazioniServizi from "./ImpostazioniServizi";
import ImpostazioniDipendenti from "./ImpostazioniDipendenti";
import ImpostazioniCode from "./ImpostazioniCode";
require ('dayjs/locale/it');

const Crea = () => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [nome, setNome] = React.useState('');
  const [tipo, setTipo] = React.useState('');
  const [indirizzo, setIndirizzo] = React.useState(null);
  const [descrizione, setDescrizione] = React.useState('');
  const [durataSlot, setDurata] = React.useState(15);
  const [orario, setOrario] = React.useState([[],[],[],[],[],[],[]]);
  const [servizi, setServizi] = React.useState([]);
  const [dipendenti, setDipendenti] = React.useState([]);
  const [code, setCode] = React.useState([]);

  const steps = [
    {
      label: 'Attivitá',
      content: <ImpostazioniAttivita image={image} setImage={setImage} nome={nome} setNome={setNome} tipo={tipo} setTipo={setTipo} indirizzo={indirizzo} setIndirizzo={setIndirizzo} descrizione={descrizione} setDescrizione={setDescrizione}></ImpostazioniAttivita>,
    },
    {
      label: 'Orario',
      content: <ImpostazioniOrario durataSlot={durataSlot} setDurata={setDurata} orario={orario} setOrario={setOrario}></ImpostazioniOrario>,
    },
    {
      label: 'Servizi',
      content: <ImpostazioniServizi servizi={servizi} setServizi={setServizi} durataSlot={durataSlot}></ImpostazioniServizi>,
    },
    {
      label: 'Dipendenti',
      content: <ImpostazioniDipendenti dipendenti={dipendenti} setDipendenti={setDipendenti}></ImpostazioniDipendenti>,
    },
    {
      label: 'Code',
      content: <ImpostazioniCode code={code} setCode={setCode} dipendenti={dipendenti} servizi={servizi}></ImpostazioniCode>,
    },
  ];

  //DA FARE: USEEFFECT SU OGNI STATO CHE CAMBIA IL VALORE DI ALTRI STATI (es se tolgo un dipendente, lo devo togliere da tutte le code che gestisce)
  //POSSO AVERE MULTIPLI USEEFFECT SEPARATI
  useEffect(() => {
    let newCode=[];
    code.forEach(coda => {
      let dipCoda=[];
      coda.dipendentiCoda.forEach(dipendenteCoda => {
        if(dipendenti.some(d => (d.id === dipendenteCoda.id ))){
          dipCoda=[...dipCoda,dipendenteCoda];
        }
      });
      coda.dipendentiCoda=dipCoda;
      newCode.push(coda);
    });
    setCode(newCode);
  },[dipendenti])
  
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%', p:1 }}>
      <Typography variant="h6">Creazione guidata nuova attivitá</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography variant="caption">Ultimo passaggio</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.content}
              <Box sx={{ mb: 1, float:"right" }}>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Indietro
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 0 }}
                  >
                    {index === steps.length - 1 ? 'Fine' : 'Avanti'}
                  </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  )
};

export default Crea;
