import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Coda from "./components_code/Coda";
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

var idVal=0;

const ImpostazioniCode = ({code,setCode,dipendenti,servizi}) => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(-1);

  function getID(){
    idVal++;
    //console.info("IDVAL: "+idVal);
    return idVal;
  }

  function creaCoda(id, nome, immagine, dipendentiCoda) {
    if(id<0)id=getID();
    return {id, nome, immagine, dipendentiCoda};
  }

  const aggiungiCoda = () => {//console.info("aggiungi");
    setCode([
      ...code,
      creaCoda(-1,"Nuova coda", "", [])
  ]);
  };

  const rimuoviCoda = (id) => {//console.info("rimuovi");
    //console.info("rimuovi: "+id);
    var c=[];
    code.forEach(coda => {
            if(coda.id!=id){
                //console.info('salva '+turno.pos+" inizio:"+ turno.inizio.format('H:m'));
                c.push(creaCoda(coda.id, coda.nome, coda.immagine, coda.dipendentiCoda));
            }
        });
    setCode(c);
  };

  const modificaCoda = (coda) => {//console.info("modifica");
      var c=[];
      code.forEach(cc => {
        if(cc.id==coda.id){
          c.push(coda);
        }else{
          c.push(cc);
        }
      });
      setCode(c);
      //t.forEach(tt=>{
         // console.info(tt.pos+" inizio:"+ tt.inizio.format('H:m')+" id:"+ tt.id);
      //})
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Typography variant="body1">Code disponibili</Typography>
      <Typography variant="body2" color="text.secondary">Ogni coda gestisce le sue prenotazioni separata dalle altre code.<br/>In quanto proprietario avrai accesso a tutte le code in automatico.</Typography>
      <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%", my:1}}>
        {code?.map((c) => (                                
          <Coda expanded={expanded} click={setExpanded} rimuovi={rimuoviCoda} cambia={modificaCoda} coda={c} dipendenti={dipendenti}/>
        ))}
        
        <Chip label="Aggiungi coda" onClick={aggiungiCoda} color="luogoraGreen" sx={{mt:1}}/>
      </Box>
    </Box>
  )
};

export default ImpostazioniCode;
