import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { styled, alpha } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Servizio from "./components_servizi/Servizio";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

var idVal=0;

const ImpostazioniServizi = ({servizi,setServizi,durataSlot}) => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(-1);

  function getID(){
    idVal++;
    //console.info("IDVAL: "+idVal);
    return idVal;
  }

  function creaServizio(id, nome, descrizione, durata) {
    if(id<0)id=getID();
    return {id, nome, descrizione, durata};
  }

  const aggiungiServizio = () => {
    setServizi([
      ...servizi,
      creaServizio(-1,"Nuovo servizio", "", 1)
  ]);
  };

  const rimuoviServizio = (id) => {
    //console.info("rimuovi: "+id);
    var s=[];
    servizi.forEach(servizio => {
            if(servizio.id!=id){
                //console.info('salva '+turno.pos+" inizio:"+ turno.inizio.format('H:m'));
                s.push(creaServizio(servizio.id,servizio.nome,servizio.descrizione,servizio.durata));
            }
        });
        setServizi(s);
  };

  const modificaServizio = (servizio) => {
      var s=[];
      servizi.forEach(ss => {
        if(ss.id==servizio.id){
          //console.info('salva '+turno.pos+" inizio:"+ turno.inizio.format('H:m'));
          s.push(servizio);
        }else{
          s.push(ss);
        }
      });
      setServizi(s);
      //t.forEach(tt=>{
         // console.info(tt.pos+" inizio:"+ tt.inizio.format('H:m')+" id:"+ tt.id);
      //})
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Typography variant="body1">Elenco servizi</Typography>
      <Typography variant="body2" color="text.secondary">Aggiungi i servizi offerti dalla tua attivitá.<br/>La durata di un servizio é definita da quanti blocchi occupa nell'orario.<br/>(la durata dei blocchi é stata impostata nel passaggio precedente)</Typography>
      
      <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%", my:1,p:0}}>
        {servizi?.map((s) => (                                
          <Servizio expanded={expanded} click={setExpanded} rimuovi={rimuoviServizio} cambia={modificaServizio} servizio={s} durataSlot={durataSlot}/>
        ))}
        <Chip sx={{mt:1}} label="Aggiungi servizio" onClick={aggiungiServizio} color="luogoraGreen"/>
      </Box>
    </Box>
  )
};

export default ImpostazioniServizi;
