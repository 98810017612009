import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  Timestamp, 
  doc,
  getDoc,
  deleteDoc
  } from "firebase/firestore";
import { db } from "../firebase";
import dayjs from 'dayjs';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import BlockIcon from '@mui/icons-material/Block';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ContentCutOutlined } from "@mui/icons-material";
//AUTOCOMPLETE
import PropTypes from 'prop-types';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

require ('dayjs/locale/it');

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Avatar alt={`${dataSet[1][2]}`} src={`${dataSet[1][1]}`} sx={{ width: 28, height: 28, mr:1}}/>
      {`${dataSet[1][2]}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

/////////////////////////////////////////
//FINE AUTOCOMPLETE
/////////////////////////////////////////

const GestisciSlotComp = ({tipo,slot,coda,ora,slotCount,prenotazione, nuovo, blocca, contatta, cancella}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [utente, setUtente] = useState();

  useEffect(() => {
    if(!utente && prenotazione && tipo=='1')
    {
      (async () => {
          const docRef = doc(db, "utenti", prenotazione.idUtente);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            setUtente(docSnap.data());
          }else {
            var fakeUser={};
            fakeUser["nome"]=prenotazione.idUtente;
            fakeUser["immagine"]="";
            setUtente(fakeUser);
          }
      })();
    }
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const nuovoClick = () => {
    nuovo(slot,ora,coda);
    console.log("nuovoClick");
    setAnchorEl(null);
  }

  const bloccaClick = () => {
    blocca(slot,ora,coda);
    setAnchorEl(null);
  }

  const contattaClick = () => {
    console.log("contattaClick");
    setAnchorEl(null);
  }

  const cancellaClick = () => {
    cancella(prenotazione.id);
    setAnchorEl(null);
  }

  switch(tipo) {
    case '0':
      return (   
        <Box sx={{ py:0.5}}>
        <Card sx={{ width:"100%", height:60}}>
          <CardActionArea onClick={handleMenu}>
            <CardContent>
              <Typography textAlign="center" variant="h5" sx={{p:0}}>
                {ora}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={nuovoClick}>
              <ListItemIcon>
                <EditCalendarIcon />
              </ListItemIcon>
              <ListItemText>Nuovo appuntamento</ListItemText>
            </MenuItem>
            <MenuItem onClick={bloccaClick}>
              <ListItemIcon>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText>Blocca slot</ListItemText>
            </MenuItem>
          </Menu>
        </Card>
        </Box>
      )
    case '1':
        return (   
          <Box sx={{ py:0.5}}>
          <CardActionArea onClick={handleMenu}>
            {utente&&
                  <Card sx={{ width:"100%",p:1, height:(slotCount*60)+((slotCount-1)*8)}}>
                  <CardHeader
                  sx={{p:0}}
                    avatar={
                      <Avatar aria-label="recipe" alt={utente.nome} src={utente.immagine}/>
                    }
                    action={
                      <Typography sx={{px:1,py:1.5}} textAlign="center" variant="h5">
                        {ora}
                      </Typography>
                    }
                    title={
                      <Typography textAlign="left" variant="body1" noWrap sx={{maxWidth:"30vw",overflow:"hidden",textOverflow:"ellipsis"}}>
                        {utente.nome}
                      </Typography>
                    }
                    subheader={
                      <Typography textAlign="left" variant="body2" color="text.secondary">
                        {prenotazione.nomeServizio}
                      </Typography>
                    }
                  />
                  </Card>
            }
          </CardActionArea>
                <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={contattaClick}>
                    <ListItemIcon>
                      <AnnouncementIcon />
                    </ListItemIcon>
                    <ListItemText>Contatta cliente</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={cancellaClick}>
                    <ListItemIcon>
                      <EventBusyIcon />
                    </ListItemIcon>
                    <ListItemText>Cancella appuntamento</ListItemText>
                  </MenuItem>
                </Menu>
          
          </Box>
        )
    case '2':
      return (   
        <Box sx={{ py:0.5}}>
          <CardActionArea onClick={handleMenu}>
            <Card sx={{ width:"100%", height:60}}>
            <CardContent sx={{p:1}}>
              <Typography textAlign="center" variant="body1" sx={{p:0}}>
                {ora}
              </Typography>
              <Typography textAlign="center" variant="body2" color="text.secondary" sx={{p:0}}>
                Bloccato
              </Typography>
            </CardContent>
            </Card>
          </CardActionArea>
            <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={cancellaClick}>
                <ListItemIcon>
                  <AnnouncementIcon />
                </ListItemIcon>
                <ListItemText>Sblocca slot</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
      )
    case '|':
      return (   
        <>
        <Divider sx={{ width:"100%" }} textAlign="center"><Typography variant="body2" color="text.secondary">{ora}</Typography></Divider>
        </>
      )
    default:
      return <></>
  }
}

const GestisciSlotCoda = ({ nuovaPrenotazione, attivita, orarioGiorno,prenotazioni,coda,blocca,cancella}) => {
    let colonnaSlots = [];
    var oraTemp;
    var slot = 0;
    if(orarioGiorno) {
      orarioGiorno.forEach(settoreOrario => {
        var slotSettore = 0;
        while (slotSettore < Object.values(settoreOrario)[0]) {
          oraTemp=dayjs(Object.keys(settoreOrario)[0],"HH:mm").add(attivita.DurataSlot * slotSettore,"minute").format("HH:mm");
          let pren = prenotazioni[coda.nome].find(p => p.slotInizio == slot);
          //console.log(pren);
          if(pren) {
            if(pren.idUtente=="") {
              colonnaSlots.push(<GestisciSlotComp key={oraTemp+slotSettore} tipo="2" ora={oraTemp} prenotazione={pren} slotCount='1' slot={slot} coda={coda.nome} cancella={cancella}/>);
              slotSettore++;
              slot++;
            }else {
              colonnaSlots.push(<GestisciSlotComp key={oraTemp+slotSettore} tipo="1" ora={oraTemp} prenotazione={pren} slotCount={pren.slotDurata} cancella={cancella}/>);
              slotSettore+=pren.slotDurata;
              slot+=pren.slotDurata;
            }
          } else {
            colonnaSlots.push(<GestisciSlotComp key={oraTemp+slotSettore} tipo="0" ora={oraTemp} slotCount='1' slot={slot} coda={coda.nome} blocca={blocca} nuovo={nuovaPrenotazione}/>);
            slotSettore++;
            slot++;
          }
        }
        colonnaSlots.push(<GestisciSlotComp key="|" tipo="|" ora="Pausa"/>);
      });
      colonnaSlots.pop();
    }else {
      colonnaSlots.push(<GestisciSlotComp key="|" tipo="|" ora="Chiuso"/>);
    }
    return <>{colonnaSlots}</>;
}

const GestisciSlot = ({ idUtente, idAttivita, utenti, attivita, prenotazioni, giorno }) => {
  const [prenotazioniGiorno, setPrenotazioni] = useState();
  const [aggiornaSlot, aggiorna] = useState(true);
  const [apriNuovaPrenotazione, setNuovaPrenotazione] = useState(false);
  const [slotCorrente, setSlotCorrente] = useState();
  const [oraInizioCorrente, setOraInizioCorrente] = useState();
  const [codaCorrente, setCodaCorrente] = useState();
  const [servizio, setServizio] = useState('');
  const [idCliente, setIdCliente] = useState('');
  const [utenteLuogora, setUtenteLuogora] = useState(true);
  const [durataCorrente, setDurata] = useState();
  const [errore, setErrore] = useState(false);
  
  const cambiaServizio = (event) => {
      setServizio(event.target.value);
  };

  const utenteLuogoraChange = (event) => {
    setUtenteLuogora(event.target.checked);
  };

  const handleClose = () => {
    setNuovaPrenotazione(false);
  };

  const nuovaPrenotazione = (slotInizio,oraInizio,coda) => {
    setSlotCorrente(slotInizio);
    setOraInizioCorrente(oraInizio);
    setCodaCorrente(coda);
    setNuovaPrenotazione(true);
  };

  const controllaSlotSufficienti = () => {
    //console.log("controlla-> slot:" + slotCorrente + " orainizio:" + oraInizioCorrente + " coda:" + codaCorrente + " durata:" + attivita.Servizi[servizio]["slot"]);
    setErrore(false);

    //console.log(attivita.orario[giorno.day().toString()]);

    if(!servizio){
      setDurata(0);
      return;
    } 
    
    setDurata(attivita.Servizi[servizio]["slot"]);

    var slotPrecedenti=0;
    attivita.orario[giorno.day().toString()].forEach(settoreOrario => {
      if(slotCorrente<Object.values(settoreOrario)[0]+slotPrecedenti&&slotCorrente+attivita.Servizi[servizio]["slot"]>Object.values(settoreOrario)[0]+slotPrecedenti) {
        console.log('fuori orario');
        setErrore(true);
        setDurata(Object.values(settoreOrario)[0]+slotPrecedenti-slotCorrente);
        return;
      }
      slotPrecedenti+=Object.values(settoreOrario)[0];
    });

    for (let pos = 0; pos < attivita.Servizi[servizio]["slot"]; pos++) {
      prenotazioniGiorno[codaCorrente].forEach(pren => {
        if(slotCorrente+pos>=pren.slotInizio && slotCorrente+pos<pren.slotInizio+pren.slotDurata) {
          //console.log(slotCorrente+pos + " occupato da prenotazione:");
          //console.log(pren);
          setErrore(true);
          setDurata(pos);
          return;
        }
      });
    }
  }

  useEffect(() => {
    controllaSlotSufficienti();
  }, [servizio,slotCorrente]);

  useEffect(() => {
    let pTemp = {};

    attivita.Code.forEach(coda => {
      pTemp[coda.nome]=[];
      if(coda.nome in prenotazioni) {
        prenotazioni[coda.nome].forEach(p => {
          if(p.dataInizio.toDate().toDateString()==giorno.toDate().toDateString()) pTemp[coda.nome].push(p);
        });
      }
    });

    setPrenotazioni(pTemp);
  }, [giorno,aggiornaSlot]);

  async function bloccaSlot(slotInizio,oraInizio,coda) {
    console.log("bloccaClick " + slotInizio + " " + coda);
    await addDoc(collection(db, "prenotazioni"), {
      idUtente: "",
      idAttivita: idAttivita,
      nomeServizio: "",
      coda: coda,
      dataInizio: Timestamp.fromDate(giorno.set("hour",oraInizio.split(":")[0]).set("minute",oraInizio.split(":")[1]).toDate()),
      slotInizio: slotInizio,
      slotDurata: 1
    });
    aggiorna(!aggiornaSlot);
  }

  async function creaPrenotazione() {
    //console.log("prenota " + slotCorrente + " " + codaCorrente + " durata:" + durataCorrente + " cliente: " + idCliente);
    
    await addDoc(collection(db, "prenotazioni"), {
      idUtente: idCliente,
      idAttivita: idAttivita,
      nomeServizio: servizio,
      coda: codaCorrente,
      dataInizio: Timestamp.fromDate(giorno.set("hour",oraInizioCorrente.split(":")[0]).set("minute",oraInizioCorrente.split(":")[1]).toDate()),
      slotInizio: slotCorrente,
      slotDurata: durataCorrente
    });
    setNuovaPrenotazione(false);
    aggiorna(!aggiornaSlot);
  }

  async function cancellaPrenotazione(idPrenotazione) {
    console.log("cancella prenotazione " + idPrenotazione);
    await deleteDoc(doc(db, "prenotazioni",idPrenotazione));
    aggiorna(!aggiornaSlot);
  }

  return(
    <>
      <TableContainer sx={{width:"calc(100vw)",height:`calc(100vh - 150px)`}}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {attivita.Code.map((coda) => (
              <TableCell
                key={coda.nome}
                style={{ minWidth: '40vw',backgroundColor: 'transparent', border:0,paddingTop:0,paddingRight:12,paddingLeft:12}}
              >
                <Card sx={{mx:-1}}>
                <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                  {coda.nome[0]}
                </Avatar>
              }
              title={coda.nome}
              sx={{p:1}}
            />
            </Card>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {prenotazioniGiorno &&
                <TableRow role="checkbox" tabIndex={-1}>
                {attivita.Code.map((coda) => (
                  <TableCell key={coda.nome} align='right' sx={{px:1}}>
                    <GestisciSlotCoda nuovaPrenotazione={nuovaPrenotazione} attivita={attivita} orarioGiorno={attivita.orario[giorno.day().toString()]} prenotazioni={prenotazioniGiorno} coda={coda} cancella={cancellaPrenotazione} blocca={bloccaSlot}/>
                  </TableCell>
                ))}
                </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
    
    <Dialog open={apriNuovaPrenotazione} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" sx={{pb:0,pt:1}}>
        <ListItemText primary="Aggiungi prenotazione" secondary={codaCorrente + " | " + giorno.format('DD MMMM YYYY ') + oraInizioCorrente} />
      </DialogTitle>
      <DialogContent sx={{width:"100%"}}>
          <FormControl error={errore} sx={{width:"100%",mt:1}}>
            <InputLabel id="servizio-select-label">Servizio</InputLabel>
            <Select labelId="servizio-select-label" id="servizio-select" value={servizio} label="Servizio" onChange={cambiaServizio} fullWidth>
                {Object.keys(attivita.Servizi)?.map((s) => (
                    <MenuItem key={s} value={s}>{s}</MenuItem>
                ))}
            </Select>
            {errore&&<FormHelperText id="component-error-text">Tempo non sufficiente!</FormHelperText>}
          </FormControl>
          <FormControl sx={{width:"100%",mt:1}}>
            {utenteLuogora?(
              <Autocomplete
                id="virtualize-demo"
                fullWidth
                disableListWrap
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                options={Array.from(utenti.values())}
                renderInput={(params) => <TextField {...params} label="Cliente" />}
                getOptionLabel={(option) => option[2]}
                renderOption={(props, option, state) => [props, option, state.index]}
                // TODO: Post React 18 update - validate this conversion, look like a hidden bug
                renderGroup={(params) => params}
                onChange={(event, newValue) => {
                  setIdCliente(newValue[0]);
                }}
              />
            ):(
              <TextField fullWidth id="outlined-basic" label="Cliente" variant="outlined" onChange={(event) => { setIdCliente(event.target.value); }}/>
            )}
            <FormControlLabel control={<Checkbox checked={utenteLuogora} onChange={utenteLuogoraChange}/>} label="Utente Luogora" />
          </FormControl>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose} color="luogoraRed">Annulla</Button>
          <Button onClick={creaPrenotazione} autoFocus>Conferma</Button>
      </DialogActions>
    </Dialog>
  </>
  );
};

export default GestisciSlot;