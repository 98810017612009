import React from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";

const SearchResult = ({ attivita }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const handleClick = (event) => {
    navigate("/attivita/" + attivita.id);
  };

  return (
    <Card sx={{ width:"100%" }}>
      <CardActionArea onClick={handleClick}>
      <CardHeader 
        
        avatar={
            <Avatar aria-label="shop" variant="rounded" src={attivita.immagineAttivita}>
            ?
            </Avatar>
        }
        title={<Typography sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>{attivita.nomeAttivita}</Typography>}
        subheader={<Typography variant="caption" color="grey" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>{attivita.tipoAttivita+" | "+attivita.indirizzo}</Typography>}
      />
      </CardActionArea>
    </Card>
  );
};

export default SearchResult;
