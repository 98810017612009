import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { styled, alpha } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Avatar from '@mui/material/Avatar';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');


//accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

//image
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

//dipendenti
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const Coda = ({expanded, click, rimuovi, cambia, coda, dipendenti}) => {
  dayjs.locale('it');
  const theme = useTheme();

  const rimuoviCoda = () => {
    rimuovi(coda.id);
  };

  const codaClick = () => {
    if(expanded===coda.id){
        click(-1);
    }else{
        click(coda.id);
    }
  };

  function creaCoda(id, nome, immagine, dipendentiCoda) {
    return {id, nome, immagine, dipendentiCoda};
  }

  const cambiaNome=(nome)=>{
    cambia(creaCoda(coda.id, nome, coda.immagine, coda.dipendentiCoda));
  }

  const cambiaImmagine=(event)=>{
    if (event.target.files && event.target.files[0]) {
        cambia(creaCoda(coda.id, coda.nome, URL.createObjectURL(event.target.files[0]), coda.dipendentiCoda));
    }
  }

  const cambiaDipendenti=(event, newValue)=>{
    //console.info(newValue);
    cambia(creaCoda(coda.id, coda.nome, coda.immagine, newValue));
  }

  return (
        <Accordion expanded={expanded === coda.id} onChange={codaClick} sx={{width:"100%"}}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{coda.nome}</Typography>
            <IconButton sx={{position:"absolute", right:10, p:0}} aria-label="delete" onClick={rimuoviCoda}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{display: 'flex', flexDirection:"column", width:"100%"}}>
                <Box sx={{ width: '100%', display:"flex",justifyContent: 'space-around',alignItems: 'center'}}>
                    <Avatar sx={{ width: '20vw', height: '20vw' }} variant="rounded" alt="A" src={coda.immagine}></Avatar>
                    <Button sx={{ml:2}} component="label" fullWidth variant="contained" startIcon={<AddAPhotoIcon />}>
                    Carica Immagine
                    <VisuallyHiddenInput type="file" onChange={cambiaImmagine} className="filetype"/>
                    </Button>
                </Box>
                <TextField sx={{my:1}} fullWidth id="outlined-basic" label="Nome coda" variant="outlined" value={coda.nome} onChange={(event) => {cambiaNome(event.target.value);}}/>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={dipendenti}
                    getOptionLabel={(option) => option.nome}
                    value={coda.dipendentiCoda}
                    onChange={cambiaDipendenti}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Gestori coda"
                        placeholder="Dipendenti"
                    />
                    )}
                />
            </Box>
          </AccordionDetails>
        </Accordion>
  )
};

export default Coda;
