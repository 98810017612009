import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const mapOptions = {
    disableDefaultUI: true,
};

function Mappa(posizione) {
    const center=posizione.posizione;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDKMZ4J6ONp-1pYt9LCIe1AGhDBHyrZvek"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            options={mapOptions}
            center={center}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
            clickableIcons={false}
        >
            <Marker position={center}/>
        </GoogleMap>
    ) : <></>
}

export default React.memo(Mappa)