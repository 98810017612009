import React, { useEffect, useState } from "react";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CardActionArea } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlaceIcon from '@mui/icons-material/Place';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const Prenotazione = ({ prenotazione }) => {
  const [user] = useAuthState(auth);
  const [attivita, setAttivita] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDeleteOpen = () => {
      setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
      setDeleteOpen(false);
  };

  const cancellaPrenotazione = async () => {
    const docRef = doc(db, "prenotazioni", prenotazione.id);
    const docSnap = await deleteDoc(docRef);
    setAnchorEl(null);
  };

  useEffect(() => {
    if(!attivita)
    {
      (async () => {
        const docRef = doc(db, "attivita", prenotazione.idAttivita);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          setAttivita(docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>{attivita &&
    <Card sx={{ width:"100%" }}>
      <CardActionArea onClick={handleMenu}>
      <CardHeader 
        avatar={
            <Avatar aria-label="shop" variant="rounded" src={attivita.immagineAttivita}>
            ?
            </Avatar>
        }
        title={<Typography sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1,mr:10}}>{attivita.nomeAttivita}</Typography>}
        subheader={prenotazione.nomeServizio}
        action={
            <Box sx={{ position:"absolute", right:20,bottom:20}}>
                <Typography variant="h5" color="text.secondary" sx={{my:"auto"}}>
                {prenotazione.dataInizio.toDate().getHours()}:{String(prenotazione.dataInizio.toDate().getMinutes()).padStart(2, '0')} 
                </Typography>
            </Box>
        }
      />
      </CardActionArea>
      <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PlaceIcon />
          </ListItemIcon>
          <ListItemText>Naviga</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <MoreTimeIcon />
          </ListItemIcon>
          <ListItemText>Segnala ritardo</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText>Cancella prenotazione</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
              Cancella prenotazione
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="body2" gutterBottom>Vuoi cancellare l'appuntamento di {prenotazione.dataInizio.toDate().toLocaleDateString("it-IT",{ dateStyle: "full"})} presso {attivita.nomeAttivita}?</Typography>
                <Typography variant="body2"><strong>Il tuo punteggio cliente potrebbe essere ridotto</strong></Typography>
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleDeleteClose} color="luogoraRed">Annulla</Button>
              <Button onClick={cancellaPrenotazione} autoFocus>Conferma</Button>
          </DialogActions>
      </Dialog>
    </Card>
      }</>
  );
};

export default Prenotazione;
