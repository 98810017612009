import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  where,
  or
} from "firebase/firestore";
import { db } from "../firebase";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const AttivitaNavBar = (attivita) => {
  const [utente] = useAuthState(auth);
  const navigate = useNavigate();
  
  const paginaPersonale = () => {
    setAnchorEl(null);
    navigate("/home");
  }

  const signOut = () => {
    auth.signOut();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" color="luogoraBlue">
    {utente &&
      <Toolbar>
        <CardHeader avatar={ <Avatar alt={attivita.attivita.nomeAttivita} src={attivita.attivita.immagineAttivita} variant="rounded"/>} title={<Typography variant="body2">{attivita.attivita.nomeAttivita}</Typography>}/>
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenu} sx={{ mr: 1 }}>
          <MenuIcon />
        </IconButton>
        
        <Menu sx={{maxWidth:"80%"}} id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>   
            <MenuItem onClick={paginaPersonale}>
              <ListItemIcon>
                <Avatar alt={utente.displayName} src={utente.photoURL} sx={{ width: 24, height: 24 }}/>
              </ListItemIcon>
              <ListItemText primary={<Box sx={{maxWidth:"100%",overflow:"hidden",textOverflow:"ellipsis"}}>{utente.displayName}</Box>} secondary="Profilo personale"/>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <ListItemIcon>
                <SettingsIcon />
                </ListItemIcon>
                <ListItemText>Impostazioni</ListItemText>
            </MenuItem>
            <MenuItem onClick={signOut}>
                <ListItemIcon>
                <LogoutIcon />
                </ListItemIcon>
                <ListItemText>Esci</ListItemText>
            </MenuItem>
        </Menu>
      </Toolbar>
    }
    </AppBar>
  );
};

export default AttivitaNavBar;
