import React, { useEffect }  from "react";
import {
  getDoc,
  setDoc,
  doc
} from "firebase/firestore";
import { db } from "../firebase";
import Logo from "../img/logo.png";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from "react-router-dom";

const Welcome = (navigation) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if(user){
      userPresente();
    }
  });

  const userPresente = async () => {
    const docSnap = await getDoc(doc(db, "utenti", user.uid));

    if (!docSnap.exists()) {
      await setDoc(doc(db, "utenti",user.uid), {
        nome:user.displayName,
        immagine:user.photoURL,
        messaggi: [],
        notifiche: []
      });
    }
    navigate("/home");
  }

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <main className="welcome">
      <img src={Logo} alt="Luogora" width="auto" height={80} />
      <p>Benvenuto</p>
      <Button color="luogoraRed" variant="contained" startIcon={<GoogleIcon/>} onClick={googleSignIn}>Accedi con Google</Button>
    </main>
  );
};

export default Welcome;
