import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import OrarioGiorno from "./components_orario/OrarioGiorno";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/system';
import Slider from '@mui/material/Slider';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

//accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

//page
const ImpostazioniOrario = ({durataSlot, setDurata, orario, setOrario}) => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('');

  const modificaOrario = (giorno,turni) => {
    //console.info("modifica "+giorno+": "+turni.length+" turni");
    var o=[...orario];
    o[giorno]=turni;
    setOrario(o);
  };

  function stringaOrario(giorno){
    var res="";
    if(orario[giorno].length==0){
      return "chiuso";
    }else {
      orario[giorno].forEach(turno=>{
        res=res+turno.inizio.format("H:mm-")+turno.fine.format("H:mm ");
      });
      return res;
    }
  }

  const giornoClick = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Typography variant="body1">Durata slot</Typography>
      <Typography variant="body2" color="text.secondary">Durata minima di un intervallo di tempo negli orari, in minuti</Typography>
      <Box sx={{ width: '100%', padding:1}}>
      <Typography variant="body1">{durataSlot} minuti</Typography>
        <Slider
          aria-label="SlotDuration"
          defaultValue={15}
          step={5}
          min={5}
          max={60}
          value={durataSlot}
          onChange={(event, newValue) => {setDurata(newValue);}}
          //slots={{ valueLabel: SliderValueLabel }}
        />
      </Box>
      <Typography variant="body1">Orari</Typography>
      <Typography variant="body2" color="text.secondary">L'orario di un turno determina quanti slot ci sono al suo interno</Typography>
      <Accordion expanded={expanded === 'Lun'} onChange={giornoClick('Lun')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Lun</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(0)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={0} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[0]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Mar'} onChange={giornoClick('Mar')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Mar</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(1)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={1} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[1]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Mer'} onChange={giornoClick('Mer')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Mer</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(2)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={2} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[2]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Gio'} onChange={giornoClick('Gio')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Gio</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(3)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={3} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[3]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Ven'} onChange={giornoClick('Ven')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Ven</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(4)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={4} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[4]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Sab'} onChange={giornoClick('Sab')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Sab</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(5)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={5} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[5]}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'Dom'} onChange={giornoClick('Dom')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Dom</Typography>
          <Typography variant="body2" color="text.secondary" sx={{position:"absolute", right:10}}>{stringaOrario(6)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrarioGiorno giorno={6} durataSlot={durataSlot} modificaOrario={modificaOrario} turni={orario[6]}/>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
};

export default ImpostazioniOrario;
