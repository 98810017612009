import React, { useEffect, useRef, useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  where,
  or
} from "firebase/firestore";
import { db } from "../firebase";
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SearchResult from "./SearchResult";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const Search = () => {
    const [utente] = useAuthState(auth);
    const [attivita, setAttivita] = useState([]);

    const [term, setTerm] = useState('')

    const onChangeTerm = (event) => {
        setTerm(event.target.value);
        //updateSearch();
    }

    const updateSearch = () => {
        if(term!=""){
            const q = query(
            collection(db, "attivita"),
            orderBy("nomeAttivita"),
            limit(50)
            );

            const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let attivita = [];
                QuerySnapshot.forEach((doc) => {
                    if(doc.data().nomeAttivita.includes(term)||doc.data().tipoAttivita.includes(term)||doc.data().indirizzo.includes(term)){
                        attivita.push({ ...doc.data(), id: doc.id });
                    }
                });
                console.log(attivita);
                setAttivita(attivita);
            });
            return () => unsubscribe;
        }else {
            let attivita = [];
            console.log(attivita);
            setAttivita(attivita);
            return;
        }
    }

    useEffect(() => {
        updateSearch();
      }, [term]);

    return (
        <Box>
            <Box style={{ position: "fixed", top: 70,left: 0,right:0,bottom:0}} sx={{ display: 'flex', flexDirection:"column",justifyContent: 'center' }}>
                <TextField id="outlined-search" label="Cerca attività" type="search" onChange={onChangeTerm} sx={{width: 'auto',mx:2,mt:1}} 
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="search" onClick={updateSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
                <List sx={{height:"100%",width: '100%',pb:10,overflow:"auto",  position: 'relative', '& ul': { padding: 0 }}}>
                    {attivita?.map((a) => (                           
                                <ListItem key={a.id}>
                                    <SearchResult attivita={a}/>
                                </ListItem> 
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default Search;
