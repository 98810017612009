import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import NavBar from "./NavBar";
import Search from "./Search";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import Prenotazione from "./Prenotazione";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
require ('dayjs/locale/it');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserHome = (navigation) => {
    var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
    dayjs.extend(isSameOrBefore)
    const navigate = useNavigate();
    const [utente] = useAuthState(auth);
    const [prenotazioni, setPrenotazioni] = useState([]);
    const [date, setDate] = useState([]);

    const [searchOpen, setSearchOpen] = useState(false);

    const handleSearchOpen = () => {
        setSearchOpen(true);
    };

    const handleSearchClose = () => {
        setSearchOpen(false);
    };

    const exit = () => {
        navigate("/");
    };

    useEffect(() => {
        if(utente){
            const q = query(
            collection(db, "prenotazioni"),
            where("idUtente","==",utente.uid),
            orderBy("dataInizio"),
            limit(50)
            );

            const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let prenotazioni = [];
            let date = [];
            QuerySnapshot.forEach((doc) => {
                if(dayjs().isSameOrBefore(dayjs(doc.data().dataInizio.toDate()),'day')) {
                    prenotazioni.push({ ...doc.data(), id: doc.id });
                    if(date.indexOf(doc.data().dataInizio.toDate().toLocaleDateString("it-IT",{ dateStyle: "full"})) === -1) {
                        date.push(doc.data().dataInizio.toDate().toLocaleDateString("it-IT",{ dateStyle: "full"}));
                    }
                }
            });
            
            //console.log(date);
            setPrenotazioni(prenotazioni);
            setDate(date);
            });
            return () => unsubscribe;
        }else{
            exit();
        }
    }, []);

    return (
        <>
            {!utente?(
                exit()
            ):(
                <Box>
                    <NavBar/>
                    <Box style={{ position: "fixed", top: 70,left: 0,right:0,bottom:0}} sx={{ display: 'flex',justifyContent: 'center' }}>
                    <List sx={{height:"100%",width: '100%',pb:10,overflow:"auto",  position: 'relative', '& ul': { padding: 0 }}} subheader={<li />}>
                        {date?.map((data) => (
                            <li key={data}>
                                <ul>
                                    <ListSubheader key={data} sx={{pt:1,backgroundColor:"luogoraWhite.main", display: 'flex',justifyContent: 'center'}} ><Chip label={data}  color="luogoraGreen"/></ListSubheader>
                                    {prenotazioni?.map((prenotazione) => (
                                        prenotazione.dataInizio.toDate().toLocaleDateString("it-IT",{ dateStyle: "full"})==data &&                                    
                                        <ListItem key={prenotazione.id}>
                                            <Prenotazione prenotazione={prenotazione}/>
                                        </ListItem> 
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </List>
                    </Box>
                    <Box style={{ position: "fixed", bottom: 20,left: 0,right:0}} sx={{ display: 'flex',justifyContent: 'center',zIndex:5000 }}>
                        <Fab variant="extended" color="luogoraRed" onClick={handleSearchOpen}> 
                            <AddIcon sx={{ mr: 1 }} />
                            Nuova prenotazione
                        </Fab>
                    </Box>
                    <Dialog fullScreen open={searchOpen} onClose={handleSearchClose} TransitionComponent={Transition} sx={{zIndex:6000 }}>
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleSearchClose} aria-label="close" >
                            <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Cerca attività
                            </Typography>
                        </Toolbar>
                        </AppBar>
                        <Search/>                   
                    </Dialog>
                </Box>
            )}
        </>
    );
};

export default UserHome;
