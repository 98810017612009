import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  Timestamp
} from "firebase/firestore";
import { db } from "../firebase";
import dayjs from 'dayjs';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

const SlotComp = ({tipo,slot,ora,conferma}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const prenotaClick = () => {
    conferma(slot,ora);
    setAnchorEl(null);
  }

  switch(tipo) {
    case '0':
      return (   
        <>
        <Card sx={{ width:"100%" }}>
          <CardActionArea onClick={handleMenu}>
            <CardContent>
              <Typography textAlign="center" variant="h5">
                {ora}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={prenotaClick}>
              <ListItemIcon>
                <EditCalendarIcon />
              </ListItemIcon>
              <ListItemText>Prenota</ListItemText>
            </MenuItem>
          </Menu>
        </Card>
        </>
      )
    case '1':
      return (   
        <>
        <Card sx={{ width:"100%",p:1}}>
              <Typography textAlign="center" variant="body1" color="text.secondary">
                {ora}
              </Typography>
              <Typography textAlign="center" variant="body2" color="text.secondary">
                occupato
              </Typography>
        </Card>
        </>
      )
    case '|':
      return (   
        <>
        <Divider sx={{ width:"100%" }} textAlign="center"><Typography variant="body2" color="text.secondary">pausa</Typography></Divider>
        </>
      )
    default:
      return <></>
  }
}

const ListaSlot = ({ idUtente, idAttivita, giorno, slot, attivita, servizio, coda, chiudiSlot, altezza }) => {
  const navigate = useNavigate();
  var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
  dayjs.extend(isSameOrBefore);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  var settoreOrario=0;
  var slotSettore=-1;
  var oraTemp;

  const [slotRischiesti, setRichiesti] = useState();
  const [prenotazione, setPrenotazione] = useState("");

  const [slotInizio, setSlotInizio] = useState();
  const [oraInizio, setOraInizio] = useState();

  useEffect(() => {
    //console.log(attivita.Orario[giorno.day().toString()]);
    if(servizio) {
      setRichiesti("".padStart(attivita.Servizi[servizio].slot,"0"));
    }
  })

  function conferma(slotInizio,ora) {
    setPrenotazione(servizio + " presso " + attivita.nomeAttivita + " il " + giorno.set("hour",ora.split(":")[0]).set("minute",ora.split(":")[1]).locale('it').format('LLL'));
    setSlotInizio(slotInizio);
    setOraInizio(ora);
    setOpen(true);
  }

  async function prenota() {
    await addDoc(collection(db, "prenotazioni"), {
      idUtente: idUtente,
      idAttivita: idAttivita,
      nomeServizio: servizio,
      coda: coda,
      dataInizio: Timestamp.fromDate(giorno.set("hour",oraInizio.split(":")[0]).set("minute",oraInizio.split(":")[1]).toDate()),
      slotInizio: slotInizio,
      slotDurata: attivita.Servizi[servizio].slot
    });
    navigate("/home");
  }

  return(
    <>
    <Card sx={{width:"100%"}}>
      {attivita && servizio && slotRischiesti &&
      <Box sx={{width:"100%", position:"relative"}}>
        <IconButton sx={{p:1.5, position:"absolute", top:0,left:0,zIndex:10}} onClick={chiudiSlot}>
          <CalendarMonthIcon/>
        </IconButton>
        <List sx={{width: '100%',maxHeight:350,pb:3,overflow:"auto",  position: 'relative', '& ul': { padding: 0 }}} subheader={<li />}>
              <li key={giorno}>
                  <ul>
                      <ListSubheader key={giorno} sx={{pt:1, display: 'flex',justifyContent: 'center'}} ><Chip label={giorno.locale('it').format('LL')}  color="luogoraGreen"/></ListSubheader> 
                      {slot.split("")?.map((s,index) => {
                        if(s=="|") {
                          settoreOrario++;
                          slotSettore=-1;
                        }else {
                          slotSettore++;
                        }
                        oraTemp=dayjs(Object.keys(attivita.orario[giorno.day().toString()][settoreOrario])[0],"HH:mm").add(attivita.DurataSlot * slotSettore,"minute").format("HH:mm");

                        if(dayjs().isSameOrBefore(giorno.set("hour",oraTemp.split(":")[0]).set("minute",oraTemp.split(":")[1]))) {
                          if(slot.substr(index).startsWith(slotRischiesti) || s!="0") {
                            return(
                              <ListItem key={giorno+index}>
                                <SlotComp tipo={s} slot={index-settoreOrario} ora={oraTemp} conferma={conferma}/>
                              </ListItem>   
                            );
                          }
                        }

                        return;
                      })}         
                  </ul>
              </li>
          </List>
      </Box>
      }
    </Card>
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
          Conferma la prenotazione
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {prenotazione}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="luogoraRed">Annulla</Button>
          <Button onClick={prenota} autoFocus>Conferma</Button>
        </DialogActions>
    </Dialog>
    </>
  );
};

export default ListaSlot;