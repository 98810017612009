import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
require ('dayjs/locale/it');

//page
const OrarioTurno = ({ durataSlot,turno,turni,min,max,rimuoviTurno,modificaTurno }) => {
    dayjs.locale('it');
    //const [inizio, setInizio] = React.useState(turno.inizio);
    //const [fine, setFine] = React.useState(turno.fine);

    var inizio=turno.inizio;
    var fine=turno.fine;

    useEffect(() => {
        //console.info("useeffect turno inizio:"+turno.inizio.format("H:mm")+" fine:"+turno.fine.format("H:mm"));
        inizio=turno.inizio;
        fine=turno.fine;
    },[turno])

    const cambiaInizioTurno = (value) => {
        //setInizio(value);
        //modificaTurno(turno.pos,value,turno.fine);
        //console.info("cambia inizio "+value.format("H:mm"));
        controllaFineTurno(value,turno.fine);
    };

    const cambiaFineTurno = (value) => {
        controllaFineTurno(turno.inizio,value);
    };

    const controllaFineTurno = (i,f) => {
        var diff=f.diff(i,'minute');
        if(diff<0){
            //setFine(i.add(durataSlot,'minute'));
            modificaTurno(turno.pos,i,i.add(durataSlot,'minute'));
        }else if(diff%durataSlot!=0){
            //setFine(i.add((Math.round(diff/durataSlot)*durataSlot),'minute'));
            modificaTurno(turno.pos,i,i.add((Math.round(diff/durataSlot)*durataSlot),'minute'));
        }else{
            //setFine(f);
            modificaTurno(turno.pos,i,f);
        }
    };

    const rimuovi = () => {
        rimuoviTurno(turno.pos);
    };

    return (
        <Box sx={{display: 'flex', flexDirection:"row",alignItems:"center", justifyContent:"space-evenly", width:"100%",gap:1,mb:1}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center"}}>
                    {/*
                    <TimeField
                    minTime={(turni[(turno.pos-1)]?turni[(turno.pos-1)].fine:dayjs('00:00',"H:mm"))}
                    maxTime={fine}
                    label="Inizio"
                    value={inizio}
                    onChange={(newValue) => cambiaInizioTurno(newValue)}
                    format="HH:mm"
                    />
                    */}
                    <TimePicker
                    minTime={(turni[(turno.pos-1)]?turni[(turno.pos-1)].fine:dayjs('00:00',"H:mm"))}
                    maxTime={fine}
                    label="Inizio"
                    value={inizio}
                    onChange={(newValue) => cambiaInizioTurno(newValue)}
                    format="HH:mm"
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center"}}>
                    {/*
                    <TimeField
                    minTime={inizio}
                    maxTime={(turni[(turno.pos+1)]?turni[(turno.pos+1)].inizio:dayjs('23:59',"H:mm"))}
                    label="Fine"
                    value={fine}
                    onChange={(newValue) => cambiaFineTurno(newValue)}
                    format="HH:mm"
                    />
                    */}
                    <TimePicker
                    minTime={inizio}
                    maxTime={(turni[(turno.pos+1)]?turni[(turno.pos+1)].inizio:dayjs('23:59',"H:mm"))}
                    label="Fine"
                    value={fine}
                    onChange={(newValue) => cambiaFineTurno(newValue)}
                    format="HH:mm"
                    />
                </Box>
            </LocalizationProvider>
            <IconButton sx={{m:0,px:0}} aria-label="delete" size="small" onClick={rimuovi}>
                <DeleteIcon fontSize="inherit" />
            </IconButton>
        </Box>
    )
};

export default OrarioTurno;
