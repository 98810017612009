import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import UserHome from "./components/UserHome";
import Welcome from "./components/Welcome";
import Attivita from "./components/Attivita";
import Prenota from "./components/Prenota";
import Gestisci from "./components/Gestisci";
import Crea from "./components/Crea";

import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome/>,
  },
  {
    path: "/home",
    element: <UserHome/>,
  },
  {
    path: "/attivita/:id",
    element: <Attivita/>,
  },
  {
    path: "/prenota/:id",
    element: <Prenota/>,
  },
  {
    path: "/gestisci/:id",
    element: <Gestisci/>,
  },
  {
    path: "/crea",
    element: <Crea/>,
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#2a9461"
    },
    luogoraRed: {
      main: "#ED6A5A",
      contrastText: "#EBE8ED"
    },
    luogoraBlue: {
      main: "#595D88",
      contrastText: "#EBE8ED"
    },
    luogoraYellow: {
      main: "#FED766",
    },
    luogoraYellowDark: {
      main: '#FEC10B',
    },
    luogoraOrange: {
      main: "#FC814A"
    },
    luogoraGreen: {
      main: "#57886C",
      contrastText: "#EBE8ED"
    },
    luogoraWhite: {
      main: "#EBE8ED"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Box sx={{height:"100%", minHeight:"100vh",backgroundColor:"luogoraWhite.main"}}>
        <RouterProvider router={router} />
      </Box>
    </ThemeProvider>
  </React.StrictMode>
);
