import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Calendario from "./Calendario";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const Prenota = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [attivita, setAttivita] = useState();
    const [coda, setCoda] = useState('');
    const [servizio, setServizio] = useState('');

    useEffect(() => {
      if(!attivita)
      {
        (async () => {
          const docRef = doc(db, "attivita", id);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            setAttivita(docSnap.data());
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        })();
      }
    });
  
    const cambiaCoda = (event) => {
        setCoda(event.target.value);
    };
  
    const cambiaServizio = (event) => {
        setServizio(event.target.value);
    };
  
    const notificaClick = (event) => {
        console.log("notifica!");
    };

    const esci = () => {
        navigate("/attivita/"+id);
    }

    return (
        <>
        <Box>
        {attivita &&
            <Box style={{ position: "fixed", top: 0,left: 0,right:0,bottom:0}} sx={{ display: 'flex', flexDirection:"column",alignItems:"center"}}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="shop" variant="rounded" src={attivita.immagineAttivita} onClick={esci}>
                        ?
                        </Avatar>
                    }
                    title={<Typography sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>{attivita.nomeAttivita}</Typography>}
                    subheader={<Typography variant="caption" color="text.secondary" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1}}>Prenota</Typography>}
                />
                <Box sx={{px:3, display: 'flex', flexDirection:"column",alignItems:"center",gap:1, flexGrow: 1}}>
                    <FormControl sx={{width:"100%"}}>
                        <InputLabel id="coda-select-label">Operatore</InputLabel>
                        <Select labelId="coda-select-label" id="coda-select" value={coda} label="Coda" onChange={cambiaCoda} sx={{width:"100%"}}>
                            {attivita.Code?.map((coda) => (
                                <MenuItem key={coda.nome} value={coda.nome}>{coda.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width:"100%"}}>
                        <InputLabel id="servizio-select-label">Servizio</InputLabel>
                        <Select labelId="servizio-select-label" id="servizio-select" value={servizio} label="Servizio" onChange={cambiaServizio} sx={{width:"100%"}}>
                            {Object.keys(attivita.Servizi)?.map((s) => (
                                <MenuItem key={s} value={s}>{s}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {coda && servizio && <Calendario id={id} attivita={attivita} coda={coda} servizio={servizio}/>}
                    <Box sx={{flexGrow: 1}}/>
                    <FormControl sx={{width:"100%"}}>
                        <Typography variant="caption" color="grey" sx={{my:2}}>Il giorno che volevi è al completo? Puoi ricevere una notifica se si libera un posto.</Typography>
                    </FormControl>
                </Box>
            </Box>
        }
        </Box>
        </>
    )
};

export default Prenota;
