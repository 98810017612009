import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
  limit
} from "firebase/firestore";
import { db } from "../firebase";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import UserSearchResult from "./UserSearchResult";
import Dipendente from "./components_dipendenti/Dipendente";
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

const ImpostazioniCode = ({dipendenti,setDipendenti}) => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [utente] = useAuthState(auth);
  const [open, setOpen] = React.useState(false);
  const [utenti, setUtenti] = useState([]);

  const [term, setTerm] = useState('')

  const onChangeTerm = (event) => {
      setTerm(event.target.value);
      //updateSearch();
  }

  const updateSearch = () => {
      if(term!=""){
          const q = query(
          collection(db, "utenti"),
          orderBy("nome"),
          limit(50)
          );

          const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
          let utenti = [];
              QuerySnapshot.forEach((doc) => {
                console.log(doc.data());
                  if(doc.data().nome.includes(term)){
                    utenti.push({ ...doc.data(), id: doc.id });
                  }
              });
              const source = QuerySnapshot.metadata.fromCache ? "local cache" : "server";

              console.log("Data from: "+source);
              console.log("Result:");
              console.log(utenti);
              setUtenti(utenti);
          });
          return () => unsubscribe;
      }else {
          let utenti = [];
          console.log(utenti);
          setUtenti(utenti);
          return;
      }
  }

  useEffect(() => {
      updateSearch();
    }, [term]);

  const apriRicerca = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const aggiungiDipendente = (utente) => {
    setOpen(false);
    let found=false;
    dipendenti.forEach(d => {
      if(d.id==utente.id){
        found=true;
        return;
      }
    });
    if(!found){
      setDipendenti([
        ...dipendenti,
        utente
      ]);
      //console.info('Aggiungi dipendente '+utente.nome);
    }
  };

  const rimuoviDipendente = (utente) => {
    var d=[];
    dipendenti.forEach(dipendente => {
            if(dipendente.id!=utente.id){
                //console.info('salva '+turno.pos+" inizio:"+ turno.inizio.format('H:m'));
                d.push(dipendente);
            }
        });
    setDipendenti(d);
    //console.info('Rimuovi dipendente '+utente.nome);
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Typography variant="body1">Elenco dipendenti</Typography>
      <Typography variant="body2" color="text.secondary">Aggiungi utenti Luogora tra i dipendenti della tua attivitá.<br/>Potranno visualizzare il calendario e gestire le prenotazioni delle code a loro assegnate.</Typography>
      <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%", mt:2}}>
        {dipendenti?.map((d) => (                                
          <Dipendente utente={d} rimuovi={rimuoviDipendente}/>
        ))}
        <Chip sx={{mt:1}} label="Aggiungi Dipendente" onClick={apriRicerca} color="luogoraGreen"/>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Aggiungi dipendente
        </DialogTitle>
        <DialogContent>
        <TextField id="outlined-search" label="Cerca utente" type="search" onChange={onChangeTerm} sx={{width: 'auto',mt:1}} 
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="search" onClick={updateSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
                <List sx={{height:"100%",width: '100%',overflow:"auto",  position: 'relative', '& ul': { padding: 0 },p:0}}>
                    {utenti?.map((u) => (                           
                                <ListItem key={u.id} sx={{px:0,py:0.5}}>
                                  <UserSearchResult utente={u} click={aggiungiDipendente}/>
                                </ListItem>      
                    ))}        
                </List>
        </DialogContent>
      </Dialog>
    </Box>
  )
};

export default ImpostazioniCode;
