import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { styled, alpha } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');


//accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Servizio = ({expanded, click, rimuovi, cambia, servizio, durataSlot}) => {
  dayjs.locale('it');

  const rimuoviServizio = () => {
    rimuovi(servizio.id);
  };

  const servizioClick = () => {
    if(expanded===servizio.id){
        click(-1);
    }else{
        click(servizio.id);
    }
  };

  function creaServizio(id, nome, descrizione, durata) {
    return {id, nome, descrizione, durata};
  }

  const cambiaNome=(nome)=>{
    cambia(creaServizio(servizio.id, nome, servizio.descrizione, servizio.durata));
  }

  const cambiaDescrizione=(descrizione)=>{
    cambia(creaServizio(servizio.id, servizio.nome, descrizione, servizio.durata));
  }

  const cambiaDurata=(durata)=>{
    cambia(creaServizio(servizio.id, servizio.nome, servizio.descrizione, durata));
  }

  return (
        <Accordion expanded={expanded === servizio.id} onChange={servizioClick} sx={{width:"100%"}}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{servizio.nome}</Typography>
            <IconButton sx={{position:"absolute", right:10, p:0}} aria-label="delete" onClick={rimuoviServizio}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{display: 'flex', flexDirection:"column", width:"100%"}}>
              <TextField fullWidth id="outlined-basic" label="Nome servizio" variant="outlined" value={servizio.nome} onChange={(event) => {cambiaNome(event.target.value);}}/>
              <TextField fullWidth sx={{ mt:1}} id="outlined-basic" label="Descrizione" variant="outlined" multiline rows={2} value={servizio.descrizione} onChange={(event) => {cambiaDescrizione(event.target.value);}}/>
              <Typography variant="body1">Durata servizio</Typography>
              <Typography variant="body2" color="text.secondary">{durataSlot*servizio.durata} minuti ({servizio.durata} {servizio.durata>1?"blocchi":"blocco"})</Typography>
              <Slider
                aria-label="Durata"
                defaultValue={1}
                step={1}
                min={1}
                max={25}
                value={servizio.durata} onChange={(event, newValue) => {cambiaDurata(newValue);}}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
  )
};

export default Servizio;
