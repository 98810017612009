import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import { doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import AttivitaNavBar from "./AttivitaNavBar";
import GestisciSlot from "./GestisciSlot";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";
require ('dayjs/locale/it');

const Gestisci = () => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const { id } = useParams();
  const [attivita, setAttivita] = useState();
  const [utenti, setUtenti] = useState(new Map());
  const [prenotazioni, setPrenotazioni] = useState();
  const [giorniAperto, setAperto] = useState([]);
  const [maxSlots, setMax] = useState();
  const [dataSelezionata, setData] = useState(dayjs());

  useEffect(() => {
    if(!attivita)
    {
      (async () => {
        const docRef = doc(db, "attivita", id);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          const queryUtenti = await getDocs(collection(db, "utenti"));
          queryUtenti.forEach((doc) => {
            setUtenti(map => new Map(map.set(doc.id, [doc.id, doc.data().immagine, doc.data().nome])));
          });
          setAttivita(docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }else if(!prenotazioni) {
      const q = query(
        collection(db, "prenotazioni"),
        where("idAttivita","==",id),
        orderBy("dataInizio")
      );

      const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        let prenotazioni = {};
        QuerySnapshot.forEach((doc) => {
          //aggiungo la prenotazione alla map di array (le key sono i nomi delle code, i value sono array di prenotazioni)
          if(!(doc.data().coda in prenotazioni)) {
            prenotazioni[doc.data().coda]=[];
          }
          prenotazioni[doc.data().coda].push({ ...doc.data(), id: doc.id });
        });
        //console.log(prenotazioni);
        setPrenotazioni(prenotazioni);
        setAperto(Object.keys(attivita.orario));

        let maxSlots = {};
        Object.keys(attivita.orario).forEach(gg => {
          let maxTemp=0;
          attivita.orario[gg].forEach(settore => {
            maxTemp+=Object.values(settore)[0];
          });
          maxSlots[gg]=maxTemp;
        });
        setMax(maxSlots);
      });
    }
  });

  function Day(props) {
    const {giorniAperto = [] ,day, outsideCurrentMonth, ...other } = props;
  
    var chiuso = (giorniAperto.indexOf(props.day.day().toString()) < 0) || props.day.toDate()<dayjs().subtract(1,"day").toDate();
    var quasi=false;
    var pieno=true;

    if(!chiuso) {
      attivita.Code.forEach(coda => {
        var slotOccupati=0;
        if(coda.nome in prenotazioni) {
          prenotazioni[coda.nome].forEach(pren => {
            if(pren.dataInizio.toDate().toDateString()==props.day.toDate().toDateString()) {
              slotOccupati += pren.slotDurata;
            }
          });
        }
        quasi=quasi || slotOccupati>=maxSlots[day.day()];
        pieno=pieno && slotOccupati>=maxSlots[day.day()];
      });
    }

    return (
      !chiuso?(
        pieno?(
          <PickersDay {...other} disabled={chiuso} outsideCurrentMonth={outsideCurrentMonth} day={day} sx={{backgroundColor: "luogoraRed.main"}}/>
        ):(quasi?(
          <PickersDay {...other} disabled={chiuso} outsideCurrentMonth={outsideCurrentMonth} day={day} sx={{backgroundColor: "luogoraYellow.main"}}/>
        ):(
          <PickersDay {...other} disabled={chiuso} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        ))
      ):(
        <PickersDay {...other} disabled={chiuso} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      )
    );
  }

  return (
    <Box>
      {attivita && prenotazioni &&
      <>
        <AttivitaNavBar attivita={attivita}/>
        <Box style={{ position: "fixed", top: 70,left: 0,right:0,bottom:0}} sx={{ display: 'flex', flexDirection:"column",alignItems:"center"}}>
            <Box sx={{px:3, display: 'flex', flexDirection:"column",alignItems:"center",gap:1, flexGrow: 1}}>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <DatePicker label="Data selezionata" format="dddd DD MMMM YYYY" defaultValue={dayjs()} sx={{mt:1.5}} views={['year', 'month', 'day']} disablePast={true} showDaysOutsideCurrentMonth={true} 
          value={dataSelezionata} 

          onChange={(nuovaData) => setData(nuovaData)}

          slots={{ day: Day }}

          slotProps={{
            day: {
              giorniAperto: giorniAperto,
            },
          }}
          />
              </LocalizationProvider>
            
              <GestisciSlot idAttivita={id} utenti={utenti} attivita={attivita} prenotazioni={prenotazioni} giorno={dataSelezionata}/>

            </Box>
        </Box>
        </>
      }
    </Box>
  )
};

export default Gestisci;
