import React, { useEffect, useState,useRef } from "react";
import { useParams  } from "react-router-dom";
import OrarioTurno from "../components_orario/OrarioTurno";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { Tune } from "@mui/icons-material";
require ('dayjs/locale/it');

var idVal=0;

//page
const OrarioGiorno = ({giorno, durataSlot, modificaOrario, turni}) => {
    dayjs.locale('it');

    function getID(){
        idVal++;
        //console.info("IDVAL: "+idVal);
        return idVal;
    }

    //useEffect(() => {
        //modificaOrario(giorno,turni);
    //},[turni])

    function creaTurno(pos, id, inizio, fine) {
        return {pos, id, inizio, fine};
    }

    const aggiungiTurno = () => {
        modificaOrario(giorno,[
            ...turni,
            creaTurno(turni.length, getID(), dayjs('8:30',"H:mm"), dayjs('12:30',"H:mm"))
        ]);
    };

    const modificaTurno = (pos,inizio,fine) => {
        var t=turni;
        t[pos]=creaTurno(pos, getID(), inizio, fine);
        
        modificaOrario(giorno,t);
        //setTurni(t);
        //t.forEach(tt=>{
           // console.info(tt.pos+" inizio:"+ tt.inizio.format('H:m')+" id:"+ tt.id);
        //})
    };

    const rimuoviTurno = (pos) => {
        var t=[];
        turni.forEach(turno => {
            if(turno.pos!=pos){
                //console.info('salva '+turno.pos+" inizio:"+ turno.inizio.format('H:m'));
                t.push(creaTurno(t.length, getID(),turno.inizio,turno.fine));
            }
        });
        
        modificaOrario(giorno,t);
        //setTurni(t);
        //t.forEach(tt=>{
            //console.info(tt.pos+" inizio:"+ tt.inizio.format('H:m'));
        //})
        //console.info('Rimuovi turno '+pos);
    };

    return (
        <Box sx={{display: 'flex', flexDirection:"column",alignItems:"center", width:"100%"}}>
            {turni?.map((t) => (                           
                <OrarioTurno key={t.id} durataSlot={durataSlot} turno={t} turni={turni} min={(turni[(t.pos-1)]?turni[(t.pos-1)].fine:dayjs('00:00',"H:mm"))} max={(turni[(t.pos+1)]?turni[(t.pos+1)].inizio:dayjs('23:59',"H:mm"))} rimuoviTurno={rimuoviTurno} modificaTurno={modificaTurno}/>
            ))}
            <Chip label="Aggiungi turno" onClick={aggiungiTurno} color="luogoraGreen"/>
        </Box>
    )
};

export default OrarioGiorno;
