import React, { useEffect, useState, useRef } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  collection,
  orderBy,
  startAt,
  onSnapshot,
  limit,
  where,
  doc, 
  getDoc,
  updateDoc, 
  arrayRemove,
  arrayUnion
} from "firebase/firestore";
import { db } from "../firebase";
import { Timestamp } from "firebase/firestore";
import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListaSlot from "./ListaSlot";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import NotificationsIcon from '@mui/icons-material/Notifications';
require ('dayjs/locale/it');

const Calendario = ({ id,attivita,coda,servizio }) => {
  dayjs.locale('it');
  const navigate = useNavigate();
  const [utente] = useAuthState(auth);
  const [aggiorna, setAggiorna] = useState(true);
  const [dataSelezionata, setData] = React.useState(dayjs());
  const [slotGiornoSelezionato, setSlotGiorno] = useState("");
  const [slot, setSlot] = useState(false);
  const [slots, setSlots] = useState(new Map());
  const [prenotazioni, setPrenotazioni] = useState([]);
  const [giorniAperto, setAperto] = useState([]);
  const [giorniPieni, setPieni] = useState([]);
  const [giorniQuasi, setQuasi] = useState([]);
  const [giorniNotifica, setNotifica] = useState([]);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [apriConfermaNotifica, setConfermaNotifica] = React.useState(false);
  const [apriCancellaNotifica, setCancellaNotifica] = React.useState(false);

  const handleClose = () => {
    setConfermaNotifica(false);
    setCancellaNotifica(false);
  };

  const openNotifica = (d,notif) => () => {
    setData(d);
    if(notif) {
      setCancellaNotifica(true);
    }else {
      setConfermaNotifica(true);
    }
  };
  
  const cancellaNotificaClick = async () => {
    const utenteRef = doc(db, "utenti", utente.uid);
    await updateDoc(utenteRef, {
      notifiche: arrayRemove({idAttivita:id,coda:coda,data:dataSelezionata.toDate(),slots:attivita.Servizi[servizio]["slot"]})
    });
    handleClose();
    setAggiorna(!aggiorna);
  };

  const notificaClick = async () => {
      const utenteRef = doc(db, "utenti", utente.uid);
      await updateDoc(utenteRef, {
        notifiche: arrayUnion({idAttivita:id,coda:coda,data:dataSelezionata.toDate(),slots:attivita.Servizi[servizio]["slot"]})
    });
    handleClose();
    setAggiorna(!aggiorna);
  };

  const ottieniNotifiche = async () => {
    const userDoc = doc(db, "utenti", utente.uid);
    const docSnap = await getDoc(userDoc);
    let n=[];
    docSnap.data().notifiche.forEach(notif => {
      if(notif.idAttivita==id && notif.coda==coda) n.push(notif.data.toDate());
    });
    setNotifica(n.map(Number));
  };


  useEffect(() => {
    if(attivita && coda && servizio){

      ottieniNotifiche();

        const q = query(
          collection(db, "prenotazioni"),
          where("idAttivita","==",id),
          where("coda","==",coda),
          orderBy("dataInizio"),
          startAt(Timestamp.fromDate(dataSelezionata.subtract(1,'month').toDate())),
          limit(1000)
        );

        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
          let prenotazioni = [];
          let slots=new Map();
          let giorno="";
          let slotgiorno="0000|0000";
          let currentDate;
          QuerySnapshot.forEach((doc) => {
            //aggiungo la prenotazione all'array
            prenotazioni.push({ ...doc.data(), id: doc.id });

            //verifica disponibilita
            currentDate=doc.data().dataInizio.toDate();

            //se la prenotazione é di un giorno nuovo, resetto le var giorno e slotgiorno
            if(giorno!=currentDate.toDateString()) {
              giorno=currentDate.toDateString();
              slotgiorno="";
              //slotgiorno contiene uno 0 per ogni slot libero nel giorno, 1 se lo slot é occupato e | dove c'é una pausa tra due gruppi di slot
              attivita.orario[currentDate.getDay()].forEach(o => {
                slotgiorno=slotgiorno.padEnd(slotgiorno.length+Object.values(o)[0],"0");
                slotgiorno=slotgiorno.concat("|");
              });
              slotgiorno=slotgiorno.slice(0,-1);
            }
            
            //aggiorni la var slotgiorno segnando a 1 gli slot occupati da questa prenotazione
            let slotPos=0;
            let nuoviSlot="";
            for (let i = 0; i < slotgiorno.length; i++) {
              if(slotgiorno[i]=="|") {
                nuoviSlot=nuoviSlot.concat("|");
              } else {
                if(slotPos>=doc.data().slotInizio && slotPos<(doc.data().slotInizio+doc.data().slotDurata)) {
                  nuoviSlot=nuoviSlot.concat("1");
                } else {
                  nuoviSlot=nuoviSlot.concat(slotgiorno[i]);
                }
                slotPos++;
              }
            }

            slotgiorno=nuoviSlot;

            slots.set(dayjs(currentDate).locale('it').format('LL'),slotgiorno);

          });
          
          //console.log(slots);
          setSlots(slots)
          setPrenotazioni(prenotazioni);
        });

        setAperto(Object.keys(attivita.orario));
        if(ref.current) setHeight(ref.current.clientHeight);

        return () => unsubscribe;
    }
  }, [coda,servizio,aggiorna]);

  const giornoClick = (d,s) => () => {
    setData(d);
    if(s) {
      setSlotGiorno(s);
    }else {
      let sg="";
      attivita.orario[d.day()].forEach(o => {
        sg=sg.padEnd(sg.length+Object.values(o)[0],"0");
        sg=sg.concat("|");
      });

      setSlotGiorno(sg.slice(0,-1));
    }
    mostraSlot();
  };

  function mostraSlot() {
    setSlot(true);
  }

  function chiudiSlot() {
    setSlot(false);
  }

  function SlotGiorno(props) {
    if(!slots)return;
    if(!servizio)return;
    const {giorniAperto = [], giorniPieni = [] ,giorniQuasi = [] ,giorniNotifica = [] ,day, outsideCurrentMonth, ...other } = props;

    const slotGiorno=slots.get(props.day.locale('it').format('LL'));

    var chiuso = (giorniAperto.indexOf(props.day.day().toString()) < 0) || props.day.toDate()<dayjs().subtract(1,"day").toDate();

    var pieno = false;
    var quasi = false;
    var notif = giorniNotifica.includes(+props.day.toDate());

    var slotRichiesti="0".repeat(attivita.Servizi[servizio]["slot"]);
    if(slotGiorno) {
      if(slotGiorno.search(slotRichiesti)>=0) {
        quasi=true;
      }else {
        pieno=true;
      }
    }

    return (
      !chiuso?(
        !outsideCurrentMonth?(
          !pieno?(
            !quasi?(  
              <Button sx={{py:1,minWidth:36, width:36,px:0,mx:0.25,borderRadius: '50%', border:0}} onClick={giornoClick(props.day,slotGiorno)}>
                <Typography variant="caption" color="text.primary" textAlign="center" display="block" sx={{height:"100%"}}>{props.day.date()}</Typography>
              </Button>
            ):(
              <Button sx={{py:1,minWidth:36, width:36,px:0,mx:0.25,borderRadius: '50%', border:0, backgroundColor: "luogoraYellow.main"}} onClick={giornoClick(props.day,slotGiorno)}>
                <Typography variant="caption" color="text.primary" textAlign="center" display="block" sx={{height:"100%"}}>{props.day.date()}</Typography>
              </Button>
            )
          ):(
            
            <Badge
              key={props.day.toString()}
              overlap="circular"
              badgeContent={notif ? <NotificationsIcon fontSize="small" color="luogoraYellowDark"/> : undefined}
            >
              <Button sx={{py:1,minWidth:36, width:36,px:0,mx:0.25,borderRadius: '50%', border:0, backgroundColor: "luogoraRed.main"}} onClick={openNotifica(props.day,notif)}>
                <Typography variant="caption" color="text.primary" textAlign="center" display="block" sx={{height:"100%"}}>{props.day.date()}</Typography>
              </Button>
            </Badge>
          )
        ):(
          <Button sx={{py:1,minWidth:36, width:36,px:0,mx:0.25,borderRadius: '50%', border:0}}>
            <Typography variant="caption" color="text.secondary" textAlign="center" display="block" sx={{height:"100%"}}>{props.day.date()}</Typography>
          </Button>
        )
      ):(
        <Button sx={{py:1,minWidth:36, width:36,px:0,mx:0.25,borderRadius: '50%', border:0}}>
          <Typography variant="caption" color="text.secondary" textAlign="center" display="block" sx={{height:"100%"}}>{props.day.date()}</Typography>
        </Button>
      )
    );
  }

  return (
    !slot?(
      <>
        <Card ref={ref} sx={{ width:"100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
          <DateCalendar views={['year', 'month', 'day']} disablePast={true} showDaysOutsideCurrentMonth={true} 
          value={dataSelezionata} 

          onChange={(nuovaData) => setData(nuovaData)}

          slots={{
            day: SlotGiorno,
          }}

          slotProps={{
            day: {
              giorniAperto: giorniAperto,
              giorniPieni,
              giorniQuasi,
              giorniNotifica,
            },
          }}
          />
          </LocalizationProvider>
        </Card>
        <Dialog open={apriConfermaNotifica} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">
                Giorno al completo!
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Non ci sono posti diponibili per questo giorno, vuoi ricevere una notifica se si libera un posto?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="luogoraRed">Annulla</Button>
                <Button onClick={notificaClick} autoFocus>Imposta notifica</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={apriCancellaNotifica} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">
                Notifica impostata
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Hai impostato una notifica per questo giorno, vuoi rimuoverla?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="luogoraRed">Annulla</Button>
                <Button onClick={cancellaNotificaClick} autoFocus>Rimuovi notifica</Button>
            </DialogActions>
        </Dialog>
        </>
      ):(
        <Card sx={{height:{height}, width:"100%" }}>
          <ListaSlot idUtente={utente.uid} idAttivita={id} giorno={dataSelezionata} slot={slotGiornoSelezionato} attivita={attivita} servizio={servizio} coda={coda} chiudiSlot={chiudiSlot} altezza={height}/>
        </Card>
      )
  );
};

export default Calendario;
